import { Box, LinearProgress, TableContainer, Typography, type SxProps } from '@mui/material';
import { PagerData, type Gallery, type GallerySimple, type ProjectBreedKey } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { useLoadingVisible } from '@ocodelib/react-hooks';
import { useIsomorphicLayoutEffect } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import { GalleryListRow } from '../../../components/GalleryListRow';
import {
  GalleryPreviewDialog,
  type GalleryPreviewDialogProps,
} from '../../../dialogs/GalleryPreviewDialog';
import { useApi } from '../../../provider/useApi';
import { handleError } from '../../../util/handle-error';

type DialogParams = {
  id: 'GalleryPreviewDialog';
  props: GalleryPreviewDialogProps;
};

type SearchParams = {
  pageNumber: number;
  rowsPerPage: number;
  accountId: number;
};

const DEFAULT_SEARCH_PARAMS = {
  pageNumber: 0,
  rowsPerPage: 30,
  accountId: 0,
};

interface Props {
  sx?: SxProps;
  accountId: number;
  breed: ProjectBreedKey;
  ninckName: string;
  onClickGallery?: (item: GallerySimple) => void;
}

export default function AuthorGalleryList(props: Props) {
  const { sx, accountId, onClickGallery, breed, ninckName } = props;
  const [searchParams, setSearchParams] = useState<SearchParams>(() => DEFAULT_SEARCH_PARAMS);
  const [pagerData, setPagerData] = useState<PagerData<Gallery>>();
  const [loading, setLoading] = useState(false);
  const loadingVisible = useLoadingVisible(loading);
  const [dialogParams, setDialogParams] = useState<DialogParams>();
  const api = useApi();
  const [refreshToken, setRefreshToken] = useState(0);

  useIsomorphicLayoutEffect(() => {
    setSearchParams((p) => ({ ...p, accountId }));
  }, [accountId]);

  // 갤러리 업데이트
  const updateGallery = (newGallery: GallerySimple) => {
    const elements = pagerData?.elements;
    if (!elements) return;
    const idx = elements.findIndex((it) => it.galleryId === newGallery.galleryId);
    if (idx < 0) return;
    const cloned: GallerySimple = {
      ...elements[idx]!,
      seenCount: newGallery.seenCount,
      favorCount: newGallery.favorCount,
      chimCount: newGallery.chimCount,
      isFavor: newGallery.isFavor,
      isChim: newGallery.isChim,
    };

    const newPagerData = {
      ...pagerData,
      elements: elements.map((it) => (it.galleryId === cloned.galleryId ? cloned : it)),
    };
    setPagerData(newPagerData);
  };

  const doLoadGalleryList = useCallback(
    async (ctx: ApiRequestContext, params: SearchParams, breed: ProjectBreedKey) => {
      setLoading(true);
      try {
        const { pagerData } = await api.gallery.search({ ctx, ...params, breed: [breed] });
        if (ctx.canceled) return;
        setPagerData(pagerData);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  useEffect(() => {
    if (searchParams.accountId === 0) return;
    const ctx = ApiRequestContext.of();
    doLoadGalleryList(ctx, searchParams, breed);
    return () => {
      ctx.cancel();
    };
  }, [doLoadGalleryList, searchParams, breed]);

  const handleClickRow = (gallery: GallerySimple) => () => {
    const galleryId = gallery.galleryId;
    if (onClickGallery) {
      onClickGallery(gallery);
      return;
    }
    setDialogParams({
      id: 'GalleryPreviewDialog',
      props: {
        galleryId,
        open: true,
        onClose: (changedGallery?: Gallery) => {
          setDialogParams(undefined);
          if (changedGallery) {
            updateGallery(changedGallery);
          }
        },
        onDeleted: () => {
          setRefreshToken(Date.now());
          setDialogParams(undefined);
        },
      },
    });
  };

  const {
    totalPages = 0,
    pageNumber = 0,
    elements = [],
    offset = 0,
    totalElements = 0,
  } = pagerData ?? {};
  const itemNumMax = totalElements - offset;

  return (
    <Box className="AuthorGalleryList-root" sx={sx}>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        <span style={{ color: '#0C89DB' }}>{ninckName} </span>의 다른 작품
      </Typography>
      <Box
        sx={{
          position: 'relative',
          '& .GalleryListRow-root': {
            borderBottom: '1px solid #ddd',
            minWidth: 630,
            '& + .GalleryListRow-root': {
              mt: 1,
            },
          },
        }}
      >
        {elements.map((gal) => (
          <TableContainer>
            <GalleryListRow
              key={gal.galleryId}
              userHidden
              onClick={handleClickRow(gal)}
              gallery={gal}
              sx={{
                '& .GalleryListRow-right': {
                  justifyContent: 'flex-end',
                },
              }}
            />
          </TableContainer>
        ))}
      </Box>

      {dialogParams?.id === 'GalleryPreviewDialog' && (
        <GalleryPreviewDialog {...dialogParams.props} />
      )}

      {loadingVisible && (
        <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
}
