import { Box, type SxProps } from '@mui/material';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import NextImage from 'next/image';

interface Props {
  sx?: SxProps;
  className?: string;
  title?: React.ReactNode;
}

export function NoDataView(props: Props) {
  const { sx, className, title = '데이터가 없습니다.' } = props;
  return (
    <Box
      className={clsx('NoDataView-root', className)}
      sx={flatSx(
        {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          pb: 4,
          '& .NoDataView-title': {
            fontSize: '1.1rem',
            lineHeight: 1.5,
            fontWeight: 400,
            color: '#4F566F',
          },

          '& .NoDataView-imageBox': {
            textAlign: 'center',
            width: 200,
            mt: 10,
            mb: {
              xs: 2,
              sm: 3,
            },
            '& img': {
              objectFit: 'contain',
              width: '100%',
              height: 'auto',
            },
          },
        },
        sx,
      )}
    >
      <Box className="NoDataView-imageBox">
        <NextImage src="/_static/images/img_notLogin.png" alt="" width={200} height={150} />
      </Box>
      <div className="NoDataView-title">{title}</div>
    </Box>
  );
}
