import { Box, Divider, type SxProps } from '@mui/material';
import type { BbsUser, GallerySimple, ProjectBreedKey } from '@ocode/domain';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import AuthorGalleryList from './components/AuthorGalleryList';
import { AuthorProfileBox } from './components/AuthorProfileBox';

interface Props {
  sx?: SxProps;
  className?: string;
  breed: ProjectBreedKey;
  author: BbsUser;
  onClickGallery?: (gallery: GallerySimple) => void;
}

export function GalleryAuthorView(props: Props) {
  const { sx, className, author, breed, onClickGallery } = props;

  return (
    <Box
      className={clsx('GalleryAuthorView-root', className)}
      sx={flatSx(
        {
          position: 'relative',
        },
        sx,
      )}
    >
      {/* <AuthorProfileBox user={author} sx={{ mb: 4 }} /> */}
      <AuthorGalleryList
        accountId={author.accountId}
        breed={breed}
        ninckName={author.nickName ?? '닉네임없음'}
        onClickGallery={onClickGallery}
        sx={{
          mt: 2,
        }}
      />
    </Box>
  );
}
