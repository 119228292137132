'use client';

import { alpha, styled, Tab, type TabProps } from '@mui/material';

type CustomTabProps = {
  activeColor?: string;
} & Omit<TabProps, 'disableRipple'>;

export const PageContentTab = styled((props: CustomTabProps) => <Tab disableRipple {...props} />, {
  shouldForwardProp: (prop) => prop !== 'activeColor',
})(({ theme, activeColor = '#000' }) => ({
  color: '#4E5052',
  transition: '0.2s',
  '&.Mui-selected': {
    color: '#26282A',
    fontWeight: 500,
  },
  [theme.breakpoints.up('xs')]: {
    padding: '0 8px',
    minWidth: 65,
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0 16px',
    minWidth: 90,
    fontSize: '1rem',
    fontWeight: 700,
  },
}));
