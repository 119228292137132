import { Box } from '@mui/material';

type breedType = {
  [key: string]: string;
};

const breedTagTitle: breedType = {
  js: '자바스크립트',
  py: '파이썬',
  p5: 'P5.js',
};

const breedTagBgColor: breedType = {
  js: '#FFF7E4',
  py: '#E8F4FF',
  p5: '#FFF2F6',
};

const breedTagtextColor: breedType = {
  js: '#EDA200',
  py: '#3672A4',
  p5: '#FF6594',
};

export function GalleryListBreedTag(props: { breed: string }) {
  const { breed } = props;
  const title = breedTagTitle[breed];
  const bgColor = breedTagBgColor[breed];
  const textColor = breedTagtextColor[breed];
  return (
    <Box
      className="GalleryListBreedTag-root"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        height: 18,
        px: 1,
        background: bgColor,
        color: textColor,
        borderRadius: '20px',
        fontSize: '0.7rem',
      }}
    >
      {title}
    </Box>
  );
}
